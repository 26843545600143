import { useEffect, useState } from 'react';
import s from './Cart.module.scss';
import Bag from './IconComponents/Bag';
import Cross from './IconComponents/Cross';
import { ShippingGift } from './ShippingGift/ShippingGift';
import { useCartActions } from '../../lib/cartState/actions';
import { useCartSelectors } from '../../lib/cartState/selectors';
import ProductCard from './ProductCard/ProductCard';
import DiscountNotice from './DiscountNotice/DiscountNotice';
import Upsells from './Upsells/Upsells';
import Summary from './Summary/Summary';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { groupMainProductByVariants } from 'utils/cartHelpers';
import BundleUpgrade from './Upsells/bundleUpgrade';
import BundleUpsell from './Upsells/BundleUpsell';
import VariantUpsells from './Upsells/VariantUpsells';
import BasicUpsells from './Upsells/BasicUpsells';
import BundleUpsells from './Upsells/BundleUpsells';

export const Cart = () => {
  const [variantsConsolidated, setVariantsConsolidated] = useState<{ colorLabel: string; quantity: number }[]>([]);
  const { toggleCartAction, calculateTotalsAction, setProductsQuantityAction } = useCartActions();
  const { isCartOpen, productsQuantity, cartProducts } = useCartSelectors();
  const { cartBundles } = useproductsdataSelectors();
  const { Settings, Cart } = usewpdataSelectors();

  useEffect(() => {
    calculateTotalsAction();
    setProductsQuantityAction();
  }, [cartProducts]);

  useEffect(() => {
    if (cartProducts?.mainProduct) {
      const groupedVariants = groupMainProductByVariants(cartProducts.mainProduct);
      setVariantsConsolidated(groupedVariants || []);
    }
  }, [cartProducts.mainProduct]);

  return (
    <section
      className={`${s.cart} ${isCartOpen ? s['cart--open'] : ''}`}
      style={{ pointerEvents: !isCartOpen ? 'none' : undefined }}
    >
      <div className={`${s.cart__overlay} ${isCartOpen ? s['cart__overlay--open'] : ''}`} onClick={toggleCartAction} />
      <section id="cart" className={`${s.cart__content} ${isCartOpen ? s['cart__content--open'] : ''}`}>
        <div className={s.cart__header}>
          <div className={s.cart__bagBox}>
            <Bag className={s.cart__bag} />
            <div className={s.cart__itemNumber} style={{ background: Cart.styles.accentColor }}>
              {productsQuantity}
            </div>
          </div>
          <h3 className={s.cart__text}>{Cart.cartLabel}</h3>
          <button className={s.cart__cross} onClick={toggleCartAction}>
            <div className={s.cart__crossBg}>
              <Cross />
            </div>
          </button>
        </div>
        <ShippingGift />
        <div className={s.productList}>
          {variantsConsolidated.length > 0 ? (
            variantsConsolidated.map((variant, index) => {
              return <ProductCard key={index} cartItem={variant} type="main-variants" />;
            })
          ) : (
            <ProductCard cartItem={cartProducts.mainProduct.product} type="main" />
          )}
          {/* selected upsells containing variants  */}
          <VariantUpsells />
          {/* basic upsells without variants */}
          <BasicUpsells />
          {/* bundle upsells with bundle pack */}
          <BundleUpsells />
        </div>
        <DiscountNotice />
        <div className={s.lower}>
          {Settings.bundleMode && <BundleUpgrade />}
          <Upsells />
          {cartProducts.mainProduct?.bundle?.length > 0 && (
            <div className={s.bundleProducts}>
              {cartBundles.map((product, key) => {
                const existingProductInCart = cartProducts.upsells.find(
                  (cartProduct) => cartProduct.product.id === product.id
                );
                if (!existingProductInCart) {
                  return <BundleUpsell product={product} key={key} />;
                }
                return null;
              })}
            </div>
          )}
        </div>
        <Summary />
      </section>
    </section>
  );
};
