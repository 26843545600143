import s from './ProductHero.module.scss';
import ImageSlider from './ImageSlider/ImageSlider';
import TitleBlock from './TitleBlock/TitleBlock';
import ContentBlock from './ContentBlock/ContentBlock';
import QuantityCards from './QuantityCards/QuantityCards';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import CtaSection from './CtaSection/CtaSection';
import Benefits from 'components/Hero/Benefits';
import { ProductFAQ } from './ProductFAQ/ProductFAQ';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

const ProductHero = ({ isHeaderVisible }: { isHeaderVisible: boolean }) => {
  const { Products } = useproductsdataSelectors();
  const { hero } = usewpdataSelectors();
  return (
    <div className={s.container} style={{ background: hero.styles.background }}>
      <div className={s.productHero}>
        <ImageSlider arrayLength={5} version={'version-1'} isSticky={!isHeaderVisible} />
        <TitleBlock />
        <ContentBlock />
        <QuantityCards product={Products} />
        <CtaSection />
        <Benefits variant="productHero" />
        <ProductFAQ />
      </div>
    </div>
  );
};

export default ProductHero;
