import { useState, useEffect } from 'react';

const useItemsLeftCountDown = (initialStock) => {
  const [currentStock, setCurrentStock] = useState(initialStock);

  useEffect(() => {
    const stockRef = setInterval(() => {
      setCurrentStock((prevStock) => {
        if (prevStock <= 5) {
          clearInterval(stockRef);
          return prevStock;
        }
        const randomNum = Math.min(Math.floor(Math.random() * 4) + 1, prevStock);
        return prevStock - randomNum;
      });
    }, 28000);

    return () => clearInterval(stockRef);
  }, [initialStock]);

  return currentStock;
};

export default useItemsLeftCountDown;
