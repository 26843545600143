import { useState } from 'react';
import PlusInCircle from './icons/PlusInCircle';
import { ModalWrapper } from '../Modals/ModalWrapper';
import { ReviewModal } from '../Modals/ReviewModal/ReviewModal';
import { SuccessModal } from '../Modals/SuccessModal/SuccessModal';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import ReactHtmlParser from 'react-html-parser';
import Star from './icons/Star';
import Stars from './icons/Stars';
import s from './Statistics.module.scss';

type props = {
  totalReviewsCount: number;
  reviewersPercent: string;
  reviewersText: string;
  averageRating: number;
};

const reviewRow = [
  { starNumber: '5', progressBar: '96' },
  { starNumber: '4', progressBar: '84' },
  { starNumber: '3', progressBar: '59' },
  { starNumber: '2', progressBar: '0' },
  { starNumber: '1', progressBar: '0' },
];

export default function Statistics() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const { Statistics } = usewpdataSelectors();

  const handleSubmit = () => {
    setIsPopupOpen(false);
    setIsSuccessOpen(true);
  };

  return (
    <section
      className={s.statistics}
      style={{
        '--bar-active': Statistics.styles.barActive,
        '--bar-passive': Statistics.styles.barPassive,
      }}
    >
      <div className={s.average}>
        <span className={s.average__number}>{Statistics.reviewsAverage}</span>
        <Stars className={s.average__icon} color={'#FFCB13'} />
        <span className={s.average__totalReviews}>{Statistics.numberOfReviews} reviews</span>
      </div>
      <div className={s.separator}></div>
      <div className={s.starBars}>
        {reviewRow.map(({ starNumber, progressBar }, index) => (
          <div key={index} className={s.starBars__row}>
            <div className={s.starBars__ratingNumber}>{starNumber}</div>
            <Star className={s.starBars__star} color="#FFCB13" />
            <div className={s.starBars__bar}>
              <div className={s.starBars__innerBar} style={{ width: `${progressBar}%` }}></div>
            </div>
          </div>
        ))}
      </div>
      <div className={s.separator}></div>
      <div className={s.recommend}>
        <div className={s.recommend__text}>{ReactHtmlParser(Statistics.percentOfReviewers)}</div>
        <div className={s.recommend__rows}>
          {Statistics.repeater &&
            Statistics.repeater.map((item, index) => (
              <div key={index} className={s.recommend__row}>
                <div className={s.recommend__rowText}>{item.tr_ceriteria_label}</div>
                <div className={s.recommend__bar}>
                  <div
                    className={s.recommend__innerBar}
                    style={{
                      width: `${item.assessment_percent}%`,
                    }}
                  ></div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={s.separatorLast}></div>
      <button className={s.writeReview} onClick={() => setIsPopupOpen(true)}>
        <div className={s.writeReview__text}>{Statistics.writeReviewLabel}</div>
        <PlusInCircle />
      </button>
      <ModalWrapper isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} showHeader title="Write a review">
        <ReviewModal onSubmit={handleSubmit} />
      </ModalWrapper>
      <ModalWrapper isOpen={isSuccessOpen} onClose={() => setIsSuccessOpen(false)} showHeader={false}>
        <SuccessModal onClose={() => setIsSuccessOpen(false)} />
      </ModalWrapper>
    </section>
  );
}
