import { useRef, useState } from 'react';
import { Stream, StreamPlayerApi } from '@cloudflare/stream-react';
import s from './TestimonialCard.module.scss';
import { Image, LoadingOverlay } from '@mantine/core';
import { PlayButton } from '../icons/PlayButton';
import StarRating from '../../Hero/icons/Stars';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import LazyLoad from 'react-lazy-load';

type TestimonialCardProps = {
  card_image: string;
  card_productImage: string;
  tr_card_description: string;
  review_rating: number;
  card_author: string;
  video_id: string;
  verifiedText: string;
  onPlay: any;
  isPlaying: boolean;
};

const TestimonialCard = ({
  card_image,
  card_productImage,
  tr_card_description,
  review_rating,
  card_author,
  video_id,
  verifiedText,
  onPlay,
  isPlaying,
}: TestimonialCardProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { Settings, Theme } = usewpdataSelectors();
  const streamPlayerRef = useRef() as React.MutableRefObject<StreamPlayerApi>;

  return (
    <div className={s.testimonialCardContainer}>
      <>
        <div
          className={s.playButtonWrapper}
          onClick={() => {
            onPlay(video_id);
          }}
        >
          {!isPlaying && <PlayButton className={s.playButtonWrapper__playBtn} />}
        </div>

        <>
          <div
            className={s.testimonialCardContainer__videoContainer}
            style={{ opacity: !isLoaded && isPlaying ? '0' : '1', zIndex: !isPlaying ? -1 : 20 }}
            onClick={() => {
              onPlay(video_id);
            }}
          >
            <Stream
              controls={false}
              streamRef={streamPlayerRef}
              autoplay
              volume={0.5}
              loop
              muted={isPlaying ? false : true}
              onLoadedData={() => setIsLoaded(true)}
              src={video_id}
              poster={card_image}
              preload={true}
              responsive={true}
              height="100px"
              style={{ scaleY: 1.02 }}
            />
            {!isPlaying && (
              <div className={s.testimonialCardContainer__mediaWrapper} style={{ zIndex: 19 }}>
                <img
                  src={card_image}
                  alt="testimonial media"
                  className={s.testimonialCardContainer__testimonialMedia}
                />
              </div>
            )}
          </div>
        </>

        <div className={s.testimonialCardContainer__content}>
          <div className={s.wrapper}>
            <Image src={card_productImage} alt="product image" className={s.productImage} width={48} height={90} />
            <div className={s.textWrapper}>
              <p className={s.author}>{card_author}</p>
              <p className={s.author__verified}>
                <LazyLoad>
                  <img src={`${Settings.root}/temp_images/verify.svg`} alt={verifiedText} />
                </LazyLoad>
                {verifiedText}
              </p>
              <StarRating rating={review_rating} className={s.testimonialCardContainer__rating} />
            </div>
          </div>
          <StarRating rating={review_rating} className={s.testimonialCardContainer__ratingTablet} />
          <p className={s.text}>{tr_card_description}</p>
        </div>
      </>
    </div>
  );
};

export default TestimonialCard;
