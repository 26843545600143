import s from './UpsellModal.module.scss';
import { useState } from 'react';
import Accordion from 'components/Accordion/Accordion';

type faq = {
  icon: string;
  tr_content: string;
  tr_title: string;
};

const FAQ = ({ faqs }: { faqs: faq[] }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={s.faq}>
      <div className={s.accordionContainer}>
        {faqs &&
          faqs?.map((faq, index) => (
            <Accordion
              key={index}
              icon={faq.icon}
              index={index}
              title={faq?.tr_title}
              description={faq?.tr_content}
              isOpen={index === openIndex}
              toggle={toggleAnswer}
              size="large"
              customStyles={s}
            />
          ))}
      </div>
    </div>
  );
};

export default FAQ;
