import s from './ContentBlock.module.scss';
import Checkmark from '../Icons/BlackCheckmark';
import IgVideos from '../IgVideos/IgVideos';
import { Divider } from '@mantine/core';
import RatingRow from './RatingRow';
import ItemsLeft from '../ItemsLeftBar/ItemsLeftBar';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useproductsdataSelectors } from 'lib/productsState/selectors';

import SizeChart from './SizeChart';

const ContentBlock = () => {
  const { hero } = usewpdataSelectors();
  const { EnableSizeVariants } = useproductsdataSelectors();

  const dividerStyles = {
    root: {
      height: 'fit-content',
    },
    label: {
      color: hero.styles.textColorPrimary,
    },
  };

  return (
    <>
      <div className={s.contentBlockDivider}></div>
      <div className={s.contentBlock}>
        <p className={s.subtitle} style={{ color: hero.styles.textColorPrimary }}>
          {hero.subtitle}
        </p>
        <div className={s.benefits}>
          {hero?.checklist &&
            hero?.checklist.map((benefit, index) => (
              <div className={s.item} key={index}>
                <Checkmark fill={hero.styles.textColorSecondary} />
                <p style={{ color: hero.styles.textColorSecondary }}>{benefit.tr_checklistItem}</p>
              </div>
            ))}
        </div>
        <div className={s.testimonialsRow}>
          {hero?.show_socials && (
            <>
              <IgVideos />
              <RatingRow />
            </>
          )}
          {EnableSizeVariants && <SizeChart />}
        </div>
        <Divider
          label={hero.divider_text}
          labelPosition="center"
          color="#E5E5E5"
          classNames={{ label: s.dividerText }}
          styles={dividerStyles}
        />
        {hero?.show_stockLeft && <ItemsLeft />}
      </div>
    </>
  );
};

export default ContentBlock;
