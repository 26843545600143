import { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import s from './UpsellModal.module.scss';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import ReviewCard from 'components/ReviewsSwiper/ReviewsCard/ReviewCard';
import { reviewSwiperSettings } from 'components/ReviewsSwiper/utils/sliderSettings';
export type Testimonial = {
  name: string;
  rating: string;
  tr_testimonial_text: string;
};

const swiperSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 200,
  autoplaySpeed: 4000000,
  cssEase: 'linear',
  className: 'center',
};

const ReviewsSwiper = ({ testimonials }: { testimonials: Testimonial[] }) => {
  return (
    <div className={s.testimonialsContainer}>
      <div className={s.testimonialsContainer__wrapper}>
        <section className={`${s.testimonialsContainer__sliderWrapper}`}>
          <Slider
            {...swiperSettings}
            appendDots={(dots) => (
              <div>
                <ul className={s.dots}> {dots} </ul>
              </div>
            )}
          >
            {testimonials &&
              testimonials.map((card, index) => (
                <ReviewCard
                  key={index}
                  tr_title={card.name}
                  tr_description={card.tr_testimonial_text}
                  user_name={card.name}
                  rating={Number(card.rating)}
                />
              ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};
export default ReviewsSwiper;
