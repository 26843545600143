import s from './reviewCard.module.scss';
import { VerifiedCheckmark } from '../icons/VerifiedCheckmark';
import StarRating from 'components/Hero/icons/Stars';

type ReviewCardData = {
  tr_title: string;
  tr_description: string;
  user_name: string;
  rating?: number;
};

export default function ReviewCard({ tr_title, tr_description, user_name, rating = 5 }: ReviewCardData) {
  return (
    <div className={s.reviewCard}>
      <div className={s.reviewCard__component}>
        <div className={s.reviewCard__userWrapper}>
          <div className={s.reviewCard__username}>{user_name}</div>
          <div className={s.reviewCard__verifiedTextWrapper}>
            <VerifiedCheckmark />
            <span className={s.reviewCard__verifiedText}>Verified</span>
          </div>
        </div>
        <StarRating rating={rating} className={s.reviewCard__stars} />
        <p className={s.reviewCard__description}>{tr_description}</p>
      </div>
    </div>
  );
}
