import StarRating from 'components/Hero/icons/Stars';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './UpsellModal.module.scss';
import { useCartSelectors } from 'lib/cartState/selectors';

const TitleBlock = () => {
  const { hero, Theme } = usewpdataSelectors();
  const { UpsellModal: Data } = useCartSelectors();
  const UpsellModalData = Data?.product;
  const title =
    UpsellModalData?.tr_display_name && UpsellModalData?.tr_display_name.length > 0
      ? UpsellModalData?.tr_display_name
      : Data?.addToCartObj?.selectedProduct?.product?.tr_title;
  return (
    <div className={s.titleBlock}>
      <div className={s.ratingBlock}>
        <StarRating rating={UpsellModalData?.rating?.rating ?? '5'} className={s.ratingStars} />
        <p className={s.ratingText}>
          {hero.star_rating.rating_text} <strong>{UpsellModalData?.rating?.rating ?? '5'}</strong>
        </p>
        <p className={s.reviewsText}>{UpsellModalData?.rating?.tr_reviews_text ?? '1356 Verified Reviews'}</p>
      </div>
      <h1 className={s.title} data-theme={Theme.heading} style={{ color: Theme.headingColor }}>
        {title}
      </h1>
    </div>
  );
};

export default TitleBlock;
