import s from './LinkList.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

export const LinkList = () => {
  const { header } = usewpdataSelectors();

  const handleZendeskClick = () => {
    window.zE.show();
    window.zE.activate();
  };

  return (
    <>
      {header.headerNavigationLinks &&
        header.headerNavigationLinks.map((item, index) => (
          <li className={`${s.linkList__listItem} ${index === 0 && 'linkList__firstChild'}`} key={index}>
            {item.link_type === 'custom' ? (
              item.custom_link.custom_link.includes('zendesk') ? (
                <button
                  style={{ background: 'none', border: 'none', cursor: 'pointer', margin: '0 auto' }}
                  className={s.linkList__listItemText}
                  onClick={() => {
                    handleZendeskClick();
                  }}
                >
                  <span style={{ color: '#404040' }}> {item.custom_link.tr_custom_link_label}</span>
                </button>
              ) : (
                <a href={item.custom_link.custom_link} className={s.linkList__listLink}>
                  <span className={s.linkList__listLink}>{item.custom_link.tr_custom_link_label}</span>
                </a>
              )
            ) : (
              <a href={item.page_link.page_link_uri} className={`${s.linkList__listLink} ${s.linkList__listItemText}`}>
                {item.page_link.tr_page_link_label}
              </a>
            )}
          </li>
        ))}
    </>
  );
};
