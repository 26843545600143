import { createSlice } from '@reduxjs/toolkit';
import { ProductPack } from '../../lib/productsState/productsTypes';
import { v4 as uuidv4 } from 'uuid';
import { productType, upsellItem } from './productsTypes';

const initialState: ProductPack = {
  Upsells:
    window.post_template?.product_page_v6_upsells.product_page_v6_upsells_repeater &&
    window.post_template?.product_page_v6_upsells.product_page_v6_upsells_repeater.map((upsell) => {
      return {
        ...upsell,
        id: uuidv4(),
      };
    }),

  PickerUpsells: window.post_template?.product_page_v6_upsells?.product_page_v6_upsells_repeater
    ? window.post_template?.product_page_v6_upsells?.product_page_v6_upsells_repeater
        ?.filter((upsell: upsellItem) => {
          if (upsell.upsell_type.some((t: string) => t === 'picker')) {
            if (upsell.products_list) {
              upsell.products_list = upsell.products_list.map((item: any) => {
                return {
                  id: uuidv4(),
                  ...item,
                };
              });
            }

            return upsell;
          }
        })
        .map((item) => {
          return {
            ...item,
            id: uuidv4(),
          };
        })
    : null,
  cartBundleUpsells: window.post_template?.product_page_v6_upsells?.product_page_v6_upsells_repeater
    ? window.post_template.product_page_v6_upsells.product_page_v6_upsells_repeater
        .filter((upsell: upsellItem) => {
          if (upsell.upsell_type.some((t: string) => t === 'cart-picker')) {
            if (upsell.bundle_list) {
              upsell.bundle_list = upsell.bundle_list.map((item: any) => {
                return {
                  ...item,
                  products_list_bundle:
                    item.products_list_bundle &&
                    item.products_list_bundle.map((innerItem) => {
                      return {
                        ...innerItem,
                        id: uuidv4(),
                      };
                    }),
                };
              });
            }
            return upsell;
          }
        })
        .map((item) => {
          return {
            ...item,
            id: uuidv4(),
          };
        })
    : null,
  Products:
    window.productData?.products &&
    window.productData.products.map((product: productType) => {
      return {
        id: uuidv4(),
        ...product,
      };
    }),
  EnableColorVariants: window?.productData?.enable_color_variants,
  EnableSizeVariants: window?.productData?.enable_size_variants,
  ProductColorVariants: window?.productData?.product_color_variants,
  ProductSizeVariants: window?.productData?.product_size_variants,
};

const productsdataSlice = createSlice({
  name: 'productsdata',
  initialState,
  reducers: {},
});

export default productsdataSlice.reducer;
