import { useState } from 'react';
import s from './ReviewItem.module.scss';
import Like from './Icons/Like';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { customerReviewItem } from '../../../lib/wpdataState/wpDataType';
import VerifiedCheckInCircle from './Icons/VerifiedCheckInCircle';
import { decode } from 'html-entities';
import StarRating from '../../Hero/icons/Stars';
import LazyLoad from 'react-lazy-load';

const ReviewItem = ({
  review,
  likeColor = '51D0A1',
  dislikeColor = 'BD1139',
}: {
  review: customerReviewItem;
  likeColor?: string;
  dislikeColor?: string;
}) => {
  const {
    initial_dislikes,
    initial_likes,
    user_name,
    review_score,
    tr_review_description,
    tr_review_heading,
    review_images,
  } = review;

  const [initialLikes, setInitialLikes] = useState<number>(Number(initial_likes));
  const [initialDislikes, setInitialDislikes] = useState<number>(Number(initial_dislikes));
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const { customerReviewsBlock, Settings } = usewpdataSelectors();

  const toggleLike = () => {
    if (disliked && !liked) {
      setLiked(true);
      setDisliked(false);
      setInitialDislikes(initialDislikes - 1);
      setInitialLikes(initialLikes + 1);
    } else if (!liked) {
      setLiked(true);
      setInitialLikes(initialLikes + 1);
    } else {
      setLiked(false);
      setInitialLikes(initialLikes - 1);
    }
  };

  const toggleDislike = () => {
    if (liked && !disliked) {
      setInitialLikes(initialLikes - 1);
      setLiked(false);
      setDisliked(true);
      setInitialDislikes(initialDislikes + 1);
    } else if (!disliked) {
      setDisliked(true);
      setInitialDislikes(initialDislikes + 1);
    } else {
      setDisliked(false);
      setInitialDislikes(initialDislikes - 1);
    }
  };

  const splitedName = user_name.split(' ');
  const initials = `${splitedName[0].charAt(0).toUpperCase() + splitedName[1].charAt(0).toUpperCase()}`;

  return (
    <section className={s['upsell']}>
      <div className={s.cardWrapper}>
        <div className={s.cardSideInfo}>
          <div className={s.userWrapper}>
            <div style={{ background: customerReviewsBlock.styles.bubbleBackground }} className={s.initials}>
              {initials}
            </div>
            <div>
              <div className={s.cardSideInfo__name}>{user_name}</div>
              <div className={s.cardSideInfo__verification}>
                <VerifiedCheckInCircle />
                <p>{customerReviewsBlock.verifiedLabel}</p>
              </div>
            </div>
          </div>

          <div className={s.cardSideInfo__recommend}>
            <Like color={likeColor} />
            <p>{customerReviewsBlock.verifiedLabel}</p>
          </div>
        </div>
        <div className={s.starsDateBox}>
          <StarRating rating={review_score} className={s.starsWrapper} />
        </div>
        <div className={s.cardMainInfo}>
          <div className={s.cardMainInfo__header}>{tr_review_heading}</div>
          <p className={s.cardMainInfo__text}>{decode(tr_review_description)}</p>
          {review_images.length > 0 && (
            <div className={s.cardMainInfo__images}>
              {review_images.map(
                (
                  url: {
                    image: string;
                  },
                  index: number
                ) => {
                  return (
                    <div className={s.cardMainInfo__clientImg} key={index}>
                      <LazyLoad>
                        <img src={url.image} width={100} height={100} alt={Settings.brandName} />
                      </LazyLoad>
                    </div>
                  );
                }
              )}
            </div>
          )}
          <div className={s.cardMainInfo__wasHelpful}>
            <span>{customerReviewsBlock.helpfulLabel}</span>
            <div className={s.likeContainer}>
              <div className={s.likeContainer__likeBox} onClick={toggleLike}>
                <Like className={s.likeContainer__icon} color={liked ? likeColor : 'E3E3ED'}></Like>
                <span className={s.likeContainer__number}>{initialLikes}</span>
              </div>
              <div className={s.likeContainer__likeBox} onClick={toggleDislike}>
                <Like
                  className={`${s.likeContainer__icon} ${s['likeContainer__icon--dislike']}`}
                  color={disliked ? dislikeColor : 'E3E3ED'}
                ></Like>
                <span className={s.likeContainer__number}>{initialDislikes}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewItem;
