import React, { useEffect, useRef, useState } from 'react';
import '../../ProductHero/QuantityCards/VariantPickers/variantsStyles.scss';
import s from './Upsells.module.scss';
import BundleVariantPickerOption from './BundleVariantPickerOption';
import { useIntersection } from '@mantine/hooks';

type bundleVariantProduct = {
  default_option: boolean;
  id: string;
  image: boolean;
  konnektive_id: string;
  old_price: string;
  price: string;
  sku: string;
  tr_title: string;
  type: string;
  upgrade_to: boolean;
};

type bundleVariant = {
  bundleUpsell: {
    amount: string;
    enable_variants: boolean;
    id: string;
    is_upgradedable: boolean;
    konnektive_id: string;
    old_price: string;
    price: string;
    product_image: boolean;
    products_list: bundleVariantProduct[];
    sku: string;
    tr_display_name: string;
    tr_divider_text: string;
    upsell_layout: string;
    upsell_type: string[];
  };
  handleClick: (value: string, key: string) => void;
  selectedVariants: {
    productId: string;
    bundleOptionId: string;
  }[];
};

const BundleVariantPicker = ({ bundleUpsell, handleClick, index }: bundleVariant) => {
  const [selectedBundle, setSelectedBundle] = useState();
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const { ref, entry } = useIntersection({
    root: selectRef.current,
    threshold: 1,
  });

  useEffect(() => {
    const defaultOption =
      bundleUpsell.products_list_bundle &&
      bundleUpsell.products_list_bundle.find((option) => option.default_option === true);
    setSelectedBundle({ ...defaultOption, parentId: bundleUpsell.parentId });
    handleClick({ ...defaultOption, parentId: bundleUpsell.parentId });
    if (bundleUpsell.products_list) {
      const defaultProduct =
        bundleUpsell.products_list && bundleUpsell.products_list.find((option) => option.default_option === true);
      setSelectedBundle(defaultProduct);
      handleClick(defaultProduct);
    }
  }, []);

  const watchInput = useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current) {
        const target = event.target as Node;

        if (!selectRef.current.contains(target)) {
          setOpen(false);
        }
      }
    }

    if (index == 1) {
      document.getElementById('cart').addEventListener('scroll', handleClickOutside);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {selectedBundle && (
        <div
          ref={selectRef}
          className={`${open ? 'variant-select--active variant-select' : 'variant-select--passive variant-select'} ${
            s.variantSelect
          }`}
          onClick={() => {
            setOpen(!open);
          }}
          aria-expanded={open}
          aria-haspopup="true"
        >
          <div className={`variant-select--label variant-select--bundle`}>
            <div className={s.bundleSelectLabels}>
              <span className={s.label} style={{ color: '#45050C' }}>
                {bundleUpsell?.tr_select_box_product_placeholder && bundleUpsell?.tr_select_box_product_placeholder}
                {bundleUpsell?.tr_select_box_placeholder && bundleUpsell?.tr_select_box_placeholder}
              </span>
              <span className={s.value}>
                {selectedBundle?.tr_description ? selectedBundle?.tr_description : selectedBundle.tr_title}
              </span>
            </div>
            <div className="variant-select--chevron" data-expanded={open}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.99999 5.5C4.84317 5.5 4.68637 5.43998 4.56681 5.32018L0.804499 1.5486C0.565167 1.30868 0.565167 0.919689 0.804499 0.679867C1.04373 0.440044 1.43169 0.440044 1.67104 0.679867L4.99999 4.01717L8.32895 0.679983C8.56828 0.440161 8.9562 0.440161 9.19541 0.679983C9.43486 0.919806 9.43486 1.30879 9.19541 1.54871L5.43316 5.3203C5.31354 5.44011 5.15675 5.5 4.99999 5.5Z"
                  fill="#828282"
                />
              </svg>
            </div>
          </div>
          <div className="custom-select"></div>
          {open && (
            <div className="custom-select--hidden" style={index === 1 ? { zIndex: '21' } : { zIndex: '10' }}>
              <div className="custom-select__wrapper">
                <ul className="custom-select__inner">
                  {bundleUpsell?.products_list &&
                    bundleUpsell?.products_list.map((option: any, index: number) => {
                      return (
                        <BundleVariantPickerOption
                          key={index}
                          product={option}
                          selectedBundle={selectedBundle}
                          handleClick={(e) => {
                            setSelectedBundle({ ...e, amount: 1 });
                            handleClick({ ...e, amount: 1 });
                          }}
                          selectedBundleProduct={selectedBundle}
                        />
                      );
                    })}
                  {bundleUpsell?.products_list_bundle &&
                    bundleUpsell?.products_list_bundle.map((option: any, index: number) => {
                      return (
                        <BundleVariantPickerOption
                          key={index}
                          product={{ ...option, parentId: bundleUpsell.parentId }}
                          selectedBundle={selectedBundle}
                          handleClick={(e) => {
                            setSelectedBundle(e);
                            handleClick(e);
                          }}
                          selectedBundleProduct={selectedBundle}
                        />
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BundleVariantPicker;
