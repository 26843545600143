import s from './Upsells.module.scss';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import StarRating from 'components/Hero/icons/Stars';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useCartActions } from 'lib/cartState/actions';
import { useCartSelectors } from 'lib/cartState/selectors';
import LazyLoad from 'react-lazy-load';

const BundleUpgrade = () => {
  const { Settings, Cart, Theme } = usewpdataSelectors();
  const { pickerUpsells } = useproductsdataSelectors();
  const { cartProducts } = useCartSelectors();
  const { changeBundleProduct } = useCartActions();

  const upgradableItem = pickerUpsells
    ?.find((upgradableItem) => upgradableItem.is_upgradedable === true)
    ?.products_list?.find((innerItem) => innerItem.upgrade_to === true);

  const checkIfAlreadyTaken = cartProducts?.mainProduct?.bundle.some((item) => item.id === upgradableItem.id);

  if (!upgradableItem || checkIfAlreadyTaken) return;

  return (
    <div className={s.upsellCard} style={{ marginBottom: '8px' }}>
      <div className={s.imgWrapper}>
        <img src={upgradableItem.image} alt={Settings.brandName} />
        <div className={s.badge} style={{ background: Cart.styles.badgeColor }}>
          <p>{Cart.bestSellerLabel}</p>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.upper}>
          <div className={s.title} data-theme={Theme.heading} style={{ color: Cart.styles.colorPrimary }}>
            {upgradableItem.tr_title}
          </div>
          <div className={s.rating}>
            <StarRating rating={5} className={s.stars} />
            <p style={{ color: Cart.styles.colorSecondary }}>1356 Verified Reviews</p>
          </div>
          <div className={s.prices}>
            <div className={s.price} style={{ color: Cart.styles.colorPrimary }}>
              {Settings.formattedString.replace(/(0\.00|0,00|0)/, upgradableItem.price.toString())}
            </div>
            <div className={s.oldPrice} style={{ color: Cart.styles.colorSecondary }}>
              {Settings.formattedString.replace(/(0\.00|0,00|0)/, upgradableItem.old_price.toString())}
            </div>
          </div>
        </div>
        <button
          className={s.upgradeBtn}
          data-theme={Theme.button}
          onClick={() =>
            changeBundleProduct(
              upgradableItem.sku,
              upgradableItem.tr_title,
              upgradableItem.id,
              pickerUpsells.find((item) => item.is_upgradedable === true).id,
              upgradableItem.price,
              upgradableItem.old_price,
              upgradableItem.konnektive_id
            )
          }
        >
          {Cart.upgradeLabel}
        </button>
      </div>
    </div>
  );
};

export default BundleUpgrade;
