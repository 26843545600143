import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import s from './Form.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

const initialValues = {
  footerEmail: '',
};

const validationSchema = Yup.object().shape({
  footerEmail: Yup.string().email('Invalid email').required('Email is required'),
});

export const Form = ({ isSubmitted }: { isSubmitted: (boolean: boolean) => void }) => {
  const { footer, Theme } = usewpdataSelectors();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        isSubmitted(true);
      }}
    >
      {({ errors, touched, values }) => {
        const isEmailTyped = errors.footerEmail && touched.footerEmail;

        return (
          <FormikForm className={s.form}>
            <div className={`${s.form__inputBox} ${s['form__inputBox--email']}`}>
              <Field
                autoComplete="off"
                className={`${s.form__input} ${values.footerEmail ? s['form__input--active'] : ''}`}
                style={{
                  border: `1px solid ${isEmailTyped ? '#BD1139' : '#E8E8E8'}`,
                }}
                id="footerEmail"
                name="footerEmail"
              />
              <label
                className={`${s.form__label} ${values.footerEmail ? s['form__label--active'] : ''}`}
                htmlFor="footerEmail"
              >
                {footer.newsletter.formInputPlaceholder}
              </label>
            </div>
            <button
              style={{
                '--button-custom-background': Theme?.customButton?.background,
                '--button-custom-backgroundHover': Theme?.customButton?.backgroudHover,
                '--button-custom-borderColor': Theme?.customButton?.borderColor,
                '--button-custom-textColor': Theme?.customButton?.textColor,
              }}
              type="submit"
              className={`${s.submitBtn} cta`}
              data-theme={Theme.button}
            >
              {footer?.newsletter?.formSubmitText}
            </button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
