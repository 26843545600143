import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './CtaSection.module.scss';
import { useEffect, useState } from 'react';
import ShippingIcon from '../Icons/ShippingIcon';
import { Amex } from 'components/SharedIcons/CreditCards/Amex';
import { DinersClub } from 'components/SharedIcons/CreditCards/DinersClub';
import { Discover } from 'components/SharedIcons/CreditCards/Discover';
import { Mastercard } from 'components/SharedIcons/CreditCards/Mastercard';
import { PayPal } from 'components/SharedIcons/CreditCards/Paypal';
import { Visa } from 'components/SharedIcons/CreditCards/Visa';

const CtaSection = () => {
  const { hero } = usewpdataSelectors();

  const initialTime = 30 * 60;
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const minutes = Math.floor((timeLeft % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeLeft % 60).toString().padStart(2, '0');

  return (
    <div className={s.ctaSection}>
      <p className={s.timer} style={{ color: hero.styles.textColorSecondary }}>
        <ShippingIcon fill={hero.styles.textColorSecondary} />
        {hero?.shopWithin}{' '}
        <strong style={{ color: hero.styles.textColorSecondary }}>
          {minutes}:{seconds}
        </strong>{' '}
        {hero?.shopWihinTwo}
      </p>
      <div className={s.creditCards}>
        <PayPal />
        <Visa />
        <Mastercard />
        <Amex />
        <DinersClub />
        <Discover />
      </div>
    </div>
  );
};

export default CtaSection;
