import StarRating from 'components/Hero/icons/Stars';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './TitleBlock.module.scss';

const TitleBlock = () => {
  const { hero, Theme, CleanSettings } = usewpdataSelectors();

  return (
    <div className={s.titleBlock}>
      {!CleanSettings.cleanMode && (
        <div className={s.ratingBlock}>
          <>
            <StarRating rating={hero.star_rating.rating} className={s.ratingStars} />
            <p className={`${s.ratingText}`} style={{ color: hero.styles.textColorSecondary }}>
              {hero.star_rating.rating_text} <strong>{hero.star_rating.rating}</strong>
            </p>
            <p className={`${s.reviewsText} ${s.onlyFromTablet}`} style={{ color: hero.styles.textColorSecondary }}>
              {hero.star_rating.ratings_count_text}
            </p>
            <p className={`${s.reviewsText} ${s.onlyMobile}`} style={{ color: hero.styles.textColorSecondary }}>
              {hero.star_rating.ratings_count_text_mobile}
            </p>
          </>
        </div>
      )}
      <h1 className={s.title} data-theme={Theme.heading} style={{ color: hero.styles.textColorPrimary }}>
        {hero.title}
      </h1>
    </div>
  );
};

export default TitleBlock;
