import React from 'react';
import { SetupCardProps } from 'lib/wpdataState/wpDataType';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import LazyLoad from 'react-lazy-load';

const SetupCard: React.FC<SetupCardProps> = ({ step_image, step_icon, tr_step_title, tr_step_description, index }) => {
  const { Setup, Settings } = usewpdataSelectors();
  return (
    <div className="easySteps__stepContainer">
      <img className="easySteps__stepImage mon-img-700-w" src={step_image} alt={Settings.brandName} />
      <div className="easySteps__stepContent">
        <LazyLoad>
          <img className="easySteps__stepIcon" alt={Settings.brandName} src={step_icon} />
        </LazyLoad>
        <h3 className="easySteps__stepHeading" style={{ color: Setup.styles.textColorPrimary }}>
          {tr_step_title}
        </h3>
        <p className="easySteps__stepDescription" style={{ color: Setup.styles.textColorSecondary }}>
          {tr_step_description}
        </p>
      </div>
    </div>
  );
};

export default SetupCard;
