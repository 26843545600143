import { TypedUseSelectorHook, useSelector } from 'react-redux';
import type { RootState } from '../store';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const usewpdataSelectors = () => {
  const appSelector = useAppSelector;
  return {
    CleanSettings: appSelector((state: RootState) => state.wpdata.cleanSettings),
    Settings: appSelector((state: RootState) => state.wpdata.settings),
    Theme: appSelector((state: RootState) => state.wpdata.theme),
    Layout: appSelector((state: RootState) => state.wpdata.layout),
    DiscountBar: appSelector((state: RootState) => state.wpdata.discountBar),
    MiddleBar: appSelector((state: RootState) => state.wpdata.middleBar),
    header: appSelector((state: RootState) => state.wpdata.header),
    hero: appSelector((state: RootState) => state.wpdata.hero),
    logoSlider: appSelector((state: RootState) => state.wpdata.logoSlider),
    videoTestimonials: appSelector((state: RootState) => state.wpdata.videoTestimonials),
    videoTestimonialsImages: appSelector((state: RootState) => state.wpdata.videoTestimonialsImages),
    TestimonialsSwiper: appSelector((state: RootState) => state.wpdata.testimonialsSwiper),
    productsSection: appSelector((state: RootState) => state.wpdata.productsSection),
    slidingTextBar: appSelector((state: RootState) => state.wpdata.slidingTextBar),
    CompetitorsComparison: appSelector((state: RootState) => state.wpdata.competitorsComparison),
    FeaturesList: appSelector((state: RootState) => state.wpdata.features),
    Statistics: appSelector((state: RootState) => state.wpdata.statistics),
    Setup: appSelector((state: RootState) => state.wpdata.setup),
    FAQ: appSelector((state: RootState) => state.wpdata.FAQ),
    Cart: appSelector((state: RootState) => state.wpdata.cart),
    Award: appSelector((state: RootState) => state.wpdata.awards),
    BaComparison: appSelector((state: RootState) => state.wpdata.baComparison),
    Expert: appSelector((state: RootState) => state.wpdata.expertBlock),
    customerReviewsBlock: appSelector((state: RootState) => state.wpdata.customerReviewsBlock),
    footer: appSelector((state: RootState) => state.wpdata.footer),
    Floater: appSelector((state: RootState) => state.wpdata.FloatingCTA),
  };
};
