import { upsellItem, upsellVariantItem } from 'lib/productsState/productsTypes';
import s from './Upsells.module.scss';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import StarRating from 'components/Hero/icons/Stars';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useCartActions } from 'lib/cartState/actions';
import UpsellVariantPicker from './UpsellVariantPicker';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const UpsellCard = ({ upsell }: { upsell: upsellItem['product'] }) => {
  const { Settings, Cart, Theme, CleanSettings } = usewpdataSelectors();
  const { setUpsellModalAction, addUpsellToCartAction, toggleCartAction } = useCartActions();
  const [selectedColorVariant, setSelectedColorVariant] = useState<upsellVariantItem | undefined>();
  const [selectedSizeVariant, setSelectedSizeVariant] = useState<upsellVariantItem | undefined>();

  useEffect(() => {
    if (upsell.color_variants_repeater && upsell.color_variants_repeater.length > 0) {
      const default_option = upsell.color_variants_repeater.filter((item) => {
        return item.is_default === true;
      })[0];
      setSelectedColorVariant(default_option);
    }
    if (upsell.size_variants_repeater && upsell.size_variants_repeater.length > 0) {
      const default_option = upsell.size_variants_repeater.filter((item) => {
        return item.is_default === true;
      })[0];
      setSelectedSizeVariant(default_option);
    }
  }, [upsell]);

  const handleAddToCart = () => {
    let variant = {};
    if (selectedColorVariant) {
      variant = {
        colorLabel: selectedColorVariant.tr_label,
        color: selectedColorVariant.sku,
        colorImage: selectedColorVariant.color_image,
      };
    }
    if (selectedSizeVariant) {
      variant = {
        ...variant,
        sizeLabel: selectedSizeVariant.tr_label,
        size: selectedSizeVariant.sku,
      };
    }

    variant =
      Object.keys(variant).length === 0
        ? null
        : {
            ...variant,
            id: `${variant.color}${variant.size ? '+' + variant.size : ''}`,
          };

    addUpsellToCartAction({
      product: { ...upsell },
      variants: variant,
    });
  };

  const handleViewAction = () => {
    setUpsellModalAction({
      product: { ...upsell, id: uuidv4(), type: 'upsell' },
      variant: [selectedColorVariant, selectedSizeVariant],
    });
    toggleCartAction();
  };

  return (
    <div className={s.upsellCard}>
      <div className={s.imgWrapper}>
        <img src={upsell.product_image} alt={upsell.tr_display_name} />
        <div className={s.badge} style={{ background: Cart.styles.badgeColor }}>
          <p>{Cart.bestSellerLabel}</p>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.upper}>
          <div className={s.title} data-theme={Theme.heading} style={{ color: Cart.styles.colorPrimary }}>
            {upsell.tr_display_name}
          </div>
          {!CleanSettings.cleanMode && (
            <div className={s.rating}>
              <StarRating rating={5} className={s.stars} />
              <p style={{ color: Cart.styles.colorSecondary }}>1356 Verified Reviews</p>
            </div>
          )}
          <div className={s.prices}>
            <div className={s.price} style={{ color: Cart.styles.colorPrimary }}>
              {Settings.formattedString.replace(/(0\.00|0,00|0)/, upsell.price.toString())}
            </div>
            <div className={s.oldPrice} style={{ color: Cart.styles.colorSecondary }}>
              {Settings.formattedString.replace(/(0\.00|0,00|0)/, upsell.old_price.toString())}
            </div>
          </div>
        </div>
        <div className={s.variantPickers}>
          {upsell.color_variants_repeater && selectedColorVariant && (
            <UpsellVariantPicker
              variants={upsell.color_variants_repeater}
              type="color"
              selectedVariant={selectedColorVariant}
              setVariant={setSelectedColorVariant}
            />
          )}
          {upsell.size_variants_repeater && selectedSizeVariant && (
            <UpsellVariantPicker
              variants={upsell.size_variants_repeater}
              type="size"
              selectedVariant={selectedSizeVariant}
              setVariant={setSelectedSizeVariant}
            />
          )}
        </div>
        <div className={s.lower}>
          {!Settings.hideAdd && (
            <button className={s.viewBtn} data-theme={Theme.button} onClick={handleViewAction}>
              {Cart.viewLabel}
            </button>
          )}
          <button
            className={`${s.addBtn} cta`}
            style={{ gridColumn: Settings.hideAdd ? '1/3' : 'initial' }}
            data-theme={Theme.button}
            onClick={handleAddToCart}
          >
            {Cart.addLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

const Upsells = () => {
  const { upsells } = useproductsdataSelectors();

  const upsellTypeUpsell = upsells.filter((item) => {
    return item.upsell_type?.some((item) => item === 'upsell');
  });

  return (
    <>
      {upsellTypeUpsell && upsellTypeUpsell.length > 0 && (
        <div className={s.upsells}>
          {upsellTypeUpsell.map((upsell, index) => (
            <UpsellCard key={index} upsell={upsell} />
          ))}
        </div>
      )}
    </>
  );
};

export default Upsells;
