import { productType } from 'lib/productsState/productsTypes';
import s from './Upsells.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useCartActions } from 'lib/cartState/actions';
import StarRating from 'components/Hero/icons/Stars';
import BundleVariantPicker from './BundleVariantPicker';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import LazyLoad from 'react-lazy-load';

const BundleUpsell = ({ product }: { product: productType }) => {
  const { Settings, Cart, Theme, CleanSettings } = usewpdataSelectors();
  const { setUpsellModalAction, addBundleUpsellToCartAction, toggleCartAction } = useCartActions();
  const [selectedProduct, setSelectedProduct] = useState({ product: product, bundle: [] });

  const totalBundlePrice =
    selectedProduct.bundle.reduce((acc, curr) => {
      return Number(acc) + (Number(curr.price) || 0);
    }, 0) + Number(selectedProduct.product.price);

  const totalOldprice =
    selectedProduct.bundle.reduce((acc, curr) => {
      return Number(acc) + (Number(curr.old_price) || 0);
    }, 0) + Number(selectedProduct.product.old_price);

  const handleViewAction = () => {
    setUpsellModalAction({
      product: { ...product, id: uuidv4(), type: 'bundle' },
      variant: [],
      addToCartObj: { selectedProduct },
    });
    toggleCartAction();
  };

  return (
    <div className={s.upsellCard} style={{ marginTop: '8px' }}>
      <div className={s.imgWrapper}>
        <img src={selectedProduct?.product?.image ? selectedProduct?.product.image : null} alt={Settings.brandName} />
        {!CleanSettings.cleanMode && (
          <div className={s.badge} style={{ background: Cart.styles.badgeColor }}>
            <p>{Cart.bestSellerLabel}</p>
          </div>
        )}
      </div>
      <div className={s.content}>
        <div className={s.upper}>
          <div className={s.title} data-theme={Theme.heading} style={{ color: Cart.styles.colorPrimary }}>
            {selectedProduct?.product?.tr_title && selectedProduct?.product?.tr_title}
          </div>
          {!CleanSettings.cleanMode && (
            <div className={s.rating}>
              <StarRating rating={5} className={s.stars} />
              <p style={{ color: Cart.styles.colorSecondary }}>1356 Verified Reviews</p>
            </div>
          )}
          <div className={s.prices}>
            <div className={s.price} style={{ color: Cart.styles.colorPrimary }}>
              {Settings.formattedString.replace(/(0\.00|0,00|0)/, totalBundlePrice.toFixed(2))}
            </div>
            <div className={s.oldPrice} style={{ color: Cart.styles.colorSecondary }}>
              {selectedProduct?.product?.old_price &&
                Settings.formattedString.replace(/(0\.00|0,00|0)/, totalOldprice.toFixed(2))}
            </div>
          </div>
        </div>
        <div className={s.bundleVariantPickers}>
          <BundleVariantPicker
            bundleUpsell={product}
            handleClick={(e) => {
              setSelectedProduct((prevState) => ({
                ...prevState,
                product: {
                  ...prevState.product,
                  price: e.price,
                  old_price: e.old_price,
                  sku: e.sku,
                  konnektive_id: e.konnektive_id,
                  amount: 1,
                  tr_title: e.tr_title,
                  tr_description: e.tr_description,
                  image: e.image,
                  tr_display_name: e.tr_display_name,
                },
              }));
            }}
          />

          {product?.bundle_list &&
            product.bundle_list.map((variant: any, index: number) => {
              return (
                <BundleVariantPicker
                  key={index}
                  index={index}
                  bundleUpsell={{ parentId: index, ...variant }}
                  handleClick={(e) => {
                    setSelectedProduct((prevState) => {
                      const existingItemIndex = prevState.bundle.findIndex((item) => item.parentId === e.parentId);
                      let updatedBundle;
                      if (existingItemIndex !== -1) {
                        updatedBundle = prevState.bundle.map((item, index) => (index === existingItemIndex ? e : item));
                      } else {
                        updatedBundle = [...prevState.bundle, e];
                      }
                      return {
                        ...prevState,
                        bundle: updatedBundle,
                      };
                    });
                  }}
                />
              );
            })}
        </div>
        <div className={s.lower}>
          {Settings.hideAdd && (
            <button className={s.viewBtn} data-theme={Theme.button} onClick={handleViewAction}>
              {Cart.viewLabel}
            </button>
          )}
          <button
            className={`${s.addBtn} cta`}
            data-theme={Theme.button}
            style={{ gridColumn: !Settings.hideAdd ? '1/3' : 'initial' }}
            onClick={() => addBundleUpsellToCartAction(selectedProduct)}
          >
            {Cart.addLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BundleUpsell;
