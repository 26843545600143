import s from './boastBlock.module.scss';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import { decode } from 'html-entities';
import LazyLoad from 'react-lazy-load';

const ExpertBlock = () => {
  const { Expert } = usewpdataSelectors();
  return (
    <div className={s.expertBlock}>
      <div className={s.leftBlock}>
        <div className={`${s.title} ${s.fromTablet}`}>{Expert.ourStory}</div>
        <div className={`${s.title} ${s.onlyMobile}`}>{Expert.title}</div>
        <div className={`${s.occupation} ${s.onlyMobile}`}>{Expert.subtitle}</div>
        <div className={s.divider}></div>
        <p className={s.text} dangerouslySetInnerHTML={{ __html: decode(Expert.text) }}></p>
        <div className={s.signature}>
          <div className={s.intials}>
            <div className={`${s.subtitle} ${s.fromTablet}`}>{Expert.withLove}</div>
            <p className={`${s.name} ${s.onlyMobile}`}>-{Expert.name}</p>
            <p className={`${s.name} ${s.fromTablet}`}>{Expert.subtitle}</p>
          </div>
          {Expert.signatureImgUrl && <img src={Expert.signatureImgUrl} alt={`${Expert.name}`} />}
        </div>
      </div>

      {Expert.avatarUrl && (
        <LazyLoad>
          <img className={s.avatar} src={Expert.avatarUrl} alt={Expert.name} />
        </LazyLoad>
      )}
    </div>
  );
};

export default ExpertBlock;
