import Slider from 'react-slick';
import s from './UpsellModal.module.scss';

const swiperSettings = {
  focusOnSelect: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  variableWidth: true,
  adaptiveHeight: true,
  className: 'center',
  centerMode: true,
  rows: 1,
};

type imageType = {
  ID: string;
  alt: string;
  url: string;
};

const UserGallery = ({ userGallery }: { userGallery: imageType[] }) => {
  return (
    <div className={s.userGallery}>
      <Slider {...swiperSettings}>
        {userGallery.map((item) => (
          <div className={s.userGallery__item} key={item.ID}>
            <img src={item.url} alt={item.alt} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default UserGallery;
