import { useState } from 'react';
import { PageRouteMobile } from './PageRouteMobile';
import s from './PageRouteSection.module.scss';
import c from './PageRouteMobile.module.scss';
import { usewpdataSelectors } from '../../../lib/wpdataState/selectors';

export const PageRouteSection = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const { footer, Settings } = usewpdataSelectors();

  const { links } = footer;

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleZendeskClick = () => {
    window.zE.show();
    window.zE.activate();
  };

  return (
    <ul className={s.mobileRoutes}>
      <li className={c.mobileRoute}>
        <div className={c.mobileRoute__linkHeader}>
          <h3 className={c.mobileRoute__heading}>{footer?.customerCareLabel}</h3>
        </div>
        <div className={c.mobileRoute__links} style={{ maxHeight: '150px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              className={c.mobileRoute__linksWrapper}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <button
                onClick={() => {
                  handleZendeskClick();
                }}
                style={{ cursor: 'pointer', border: 'none', background: 'none', color: '#fff' }}
                className={c.mobileRoute__link}
              >
                <img
                  style={{ marginLeft: '-8px' }}
                  src={`${window?.template_root}/images/icons/support.svg`}
                  alt={Settings.brandName}
                />
                Help Center
              </button>
              <a href={`mailto:${Settings.email}`} className={c.mobileRoute__link}>
                <img src={`${window?.template_root}/images/icons/email.svg`} alt={Settings.brandName} />
                {footer.emailSupportLabel}
              </a>
              <a href={`mailto:${Settings.email}`} className={c.mobileRoute__link}>
                <img src={`${window?.template_root}/images/icons/chat.svg`} alt={Settings.brandName} />
                {footer.liveChatLabel}
              </a>
              <a href={`tel:${Settings.phone}`} className={c.mobileRoute__link}>
                <img src={`${window?.template_root}/images/icons/phone.svg`} alt={Settings.brandName} />
                {Settings.phone}
              </a>
            </div>
          </div>
        </div>
      </li>

      {links &&
        links.map(({ tr_footer_links_column_title, footer_links_column_repeater }, index) => (
          <PageRouteMobile
            key={index}
            index={index}
            header={tr_footer_links_column_title}
            links={footer_links_column_repeater}
            isOpen={index === openIndex}
            toggleAnswer={toggleAnswer}
          />
        ))}
    </ul>
  );
};
