import s from './SubBox.module.scss';
import { useState } from 'react';
import { Form } from './Form/Form';
import StarRating from '../../Hero/icons/Stars';
import CheckWhite from '../IconComponents/CheckWhite';
import { usewpdataSelectors } from '../../../lib/wpdataState/selectors';

export const SubBox = () => {
  const { footer, CleanSettings } = usewpdataSelectors();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const handleSubmit = (boolean: boolean) => {
    setIsSubmitted(boolean);
  };

  return (
    <div className={s.subBox}>
      <div>
        <h2 className={s.subBox__header}>{footer.newsletter.title}</h2>
        {!CleanSettings.cleanMode && (
          <p className={s.subBox__rating}>
            <StarRating rating={footer.newsletter.rating} className={s.stars} />
            <span>Verified Reviews</span>
          </p>
        )}
      </div>
      <div className={s.subBox__form}>
        <Form
          isSubmitted={(bool) => {
            handleSubmit(bool);
          }}
        />
        <p className={s.subBox__submitText}>
          {!isSubmitted ? (
            footer.newsletter.textAfterSubscribeForm
          ) : (
            <>
              <CheckWhite fill="white" />
              Thank you for subscribing{' '}
            </>
          )}
        </p>
      </div>
    </div>
  );
};
