import React from 'react';
import '../../ProductHero/QuantityCards/QuantityCards.module.scss';

type VariantOptionProps = {
  product: {
    default_option: boolean;
    id: string;
    image: string;
    konnektive_id: string;
    old_price: string;
    price: string;
    sku: string;
    tr_title: string;
    type: string;
    upgrade_to: boolean;
  };
  selectedBundle: any;
  selectedBundleProduct:
    | {
        productId: string;
        bundleOptionId: string;
      }
    | undefined;
  handleClick: (value: string, key: string) => void;
};

const BundleVariantPickerOption = ({
  product,
  selectedBundle,

  handleClick,
}: VariantOptionProps) => {
  return (
    <li
      className={`custom-select--item ${selectedBundle.sku === product.sku && 'custom-select--item--active'}`}
      onClick={() => {
        handleClick(product);
      }}
    >
      <span>{product.tr_description ? product.tr_description : product.tr_title}</span>
    </li>
  );
};

export default BundleVariantPickerOption;
