import Marquee from 'react-fast-marquee';
import s from './Topbar.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { decode } from 'html-entities';
import { useEffect, useState } from 'react';

const Topbar = () => {
  const { DiscountBar, MiddleBar, Layout } = usewpdataSelectors();
  const initialTime = 2 * 80 * 60;
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const hours = Math.floor(timeLeft / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((timeLeft % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeLeft % 60).toString().padStart(2, '0');
  return (
    <div>
      <div className={s.topbar} style={{ background: DiscountBar.styles?.background }}>
        <div style={{ color: DiscountBar.styles?.textColor }}>{DiscountBar.text}</div>
        <div className={s.countdown}>
          <div className={s.card}>
            <div className={s.value}>{hours}</div>
            <div className={s.label}>HRS</div>
          </div>
          <p className={s.seperator}>:</p>
          <div className={s.card}>
            <div className={s.value}>{minutes}</div>
            <div className={s.label}>MIN</div>
          </div>
          <p className={s.seperator}>:</p>
          <div className={s.card}>
            <div className={s.value}>{seconds}</div>
            <div className={s.label}>SEC</div>
          </div>
        </div>
      </div>
      {MiddleBar.list && Layout?.middleBar.display && (
        <div className={s.scrollbar} style={{ background: MiddleBar.styles.background }}>
          <div className={s.wrapper}>
            <Marquee autoFill={true}>
              {MiddleBar?.list.map((text: { tr_text: string }, index: number) => {
                return (
                  <div className={s.text} key={index}>
                    {decode(text.tr_text)}
                  </div>
                );
              })}
            </Marquee>
          </div>
        </div>
      )}
    </div>
  );
};

export default Topbar;
