import { useCartSelectors } from 'lib/cartState/selectors';
import { Check } from './icons/Check';
import s from './UpsellModal.module.scss';

type benefits = {
  tr_title: string;
  benefits_list: { tr_benefit: string }[];
};

const Benefits = ({ benefits }: { benefits: benefits }) => {
  return (
    <>
      <div className={s.benefits}>
        <div className={s.title}>{benefits.tr_title}</div>
        <ul className={s.list}>
          {benefits.benefits_list &&
            benefits.benefits_list.map((benefit, index) => (
              <li key={index}>
                <Check />
                {benefit.tr_benefit}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default Benefits;
