import s from './ShippingGift.module.scss';
import { useCartSelectors } from '../../../lib/cartState/selectors';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

export const ShippingGift = () => {
  const { subTotal } = useCartSelectors();
  const { Cart, Settings } = usewpdataSelectors();
  const freeShippingPrice = Cart.freeShippingPrice;

  const shippingGiftTextSelector = () => {
    let count = 0;
    let text = '';
    const isShippingFree = subTotal > freeShippingPrice;

    if (!isShippingFree) {
      count = freeShippingPrice - subTotal;
      text =
        Cart.freeShippingLabel &&
        Cart.freeShippingLabel.replace(
          '€15',
          Settings.formattedString.replace(/(0\.00|0,00|0)/, count.toFixed(2).toString())
        );
    }

    if (isShippingFree) {
      text = Cart.freeShippingUnlockLabel;
    }

    return text;
  };

  return (
    <section className={s.shippingGift}>
      <div className={s.shippingGift__textWrapper}>
        <img
          src={`${Settings.root}/images/icons/sparkler.svg`}
          alt={Settings.brandName}
          data-show={subTotal > freeShippingPrice}
        />
        <p
          className={s.shippingGift__text}
          dangerouslySetInnerHTML={{ __html: shippingGiftTextSelector() }}
          style={{ color: Cart.styles.colorPrimary }}
        />
        <img
          src={`${Settings.root}/images/icons/sparkler_1.svg`}
          alt={Settings.brandName}
          data-show={subTotal > freeShippingPrice}
        />
      </div>
      <div className={s.shippingGift__progressBar}>
        <div
          className={s.shippingGift__progressBarColor}
          style={{
            width: `${subTotal >= freeShippingPrice ? '100' : (subTotal * 100) / freeShippingPrice}%`,
            background: Cart.styles.shippingBarColor,
          }}
        ></div>
        <div className={s.shippingGift__freeShipping} style={{ left: `calc(${freeShippingPrice}% - 45px)` }}></div>
      </div>
    </section>
  );
};
