import s from './ProductCard.module.scss';
import RemoveIcon from '../Icons/RemoveIcon';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { formatPrice } from 'utils/helpers';
import { cartProductItem } from 'lib/cartState/types';
import { useCartActions } from 'lib/cartState/actions';
import { useCartSelectors } from 'lib/cartState/selectors';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import LazyLoad from 'react-lazy-load';

type props = {
  cartItem: cartProductItem['product'];
  type: 'main' | 'main-variants' | 'upsell';
};

const ProductCard = ({ cartItem, type }: props) => {
  const {
    removeUpsellFromCartAction,
    adjustUpsellQuantityAction,
    incrementMainProductAction,
    decrementMainProductAction,
    removeVariantsMainProductAction,
  } = useCartActions();
  const { Settings, hero, Cart, Theme } = usewpdataSelectors();
  const { cartProducts } = useCartSelectors();
  const { Products, EnableColorVariants, EnableSizeVariants } = useproductsdataSelectors();

  const bundleProps = cartProducts.mainProduct.bundle;
  const bundleItemsPrice = bundleProps ? bundleProps.reduce((acc, item) => acc + Number(item.price), 0) : 0;
  const bundleItemsOldPrice = bundleProps
    ? bundleProps.reduce((acc, item) => (acc + Number(item.old_price) ? Number(item.old_price) : 0), 0)
    : 0;

  const increaseQuantity = () => {
    const newAmount = Number(cartProducts?.mainProduct.product.amount) + 1;

    if (type === 'upsell') {
      adjustUpsellQuantityAction(cartItem.sku, Number(cartItem.amount) + 1);
    } else if (EnableColorVariants || EnableColorVariants) {
      const selectedProduct = Products.filter((item) => {
        return Number(item.amount) === Number(cartProducts.mainProduct?.product?.amount) + 1;
      });

      let productToAdjust = selectedProduct[0];

      if (!productToAdjust) {
        productToAdjust = Products.reduce((maxItem, item) => {
          return Number(item.amount) > Number(maxItem.amount) ? item : maxItem;
        }, Products[0]);
      }

      productToAdjust = {
        ...productToAdjust,
        amount: newAmount.toString(),
      };

      incrementMainProductAction(productToAdjust, cartItem.id);
    } else {
      incrementMainProductAction(newAmount, null);
    }
  };

  const decreaseQuantity = () => {
    if (cartItem.amount > 1) {
      const newAmount = Number(cartProducts.mainProduct?.product?.amount) - 1;

      if (type === 'upsell') {
        adjustUpsellQuantityAction(cartItem.sku, Number(cartItem.amount) - 1);
      } else if (EnableColorVariants || EnableSizeVariants) {
        const selectedProduct = Products.find((item) => {
          return Number(item.amount) === newAmount;
        });

        let productToAdjust;
        if (selectedProduct) {
          productToAdjust = selectedProduct;
        } else {
          productToAdjust = Products.reduce((maxItem, item) => {
            return Number(item.amount) > Number(maxItem.amount) ? item : maxItem;
          }, Products[0]);
        }

        productToAdjust = {
          ...productToAdjust,
          amount: newAmount,
        };

        decrementMainProductAction(productToAdjust, cartItem.id);
      } else {
        incrementMainProductAction(newAmount, null);
      }
    }
  };

  return (
    <>
      <div className={s.productCard}>
        <div className={s.quantityWrapper}>
          <div className={s.imageWrapper}>
            <div className={s.sumOfItem} style={{ background: Cart.styles.colorPrimary }}>
              {cartItem.amount}x
            </div>
            <div className={s.image}>
              <LazyLoad>
                <img
                  className={s.productImage}
                  src={cartItem?.image?.url ? cartItem?.image?.url : cartItem.image}
                  alt={Settings.bundleMode ? hero.title : cartItem.display_name}
                />
              </LazyLoad>
            </div>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.upper}>
            <div className={s.titles}>
              <div className={s.title} data-theme={Theme.heading} style={{ color: Cart.styles.colorPrimary }}>
                {Settings.bundleMode ? hero.title : cartItem.display_name}
              </div>
              {cartItem?.description && (
                <div className={s.description} style={{ color: Cart.styles.colorSecondary }}>
                  {cartItem?.description}
                </div>
              )}
              {Settings.bundleMode && (
                <>
                  <div className={s.description} style={{ color: Cart.styles.colorSecondary }}>
                    {cartItem.display_name}
                  </div>

                  {bundleProps.map((bundleProp, index) => (
                    <div key={index} className={s.description} style={{ color: Cart.styles.colorSecondary }}>
                      {bundleProp.label}
                    </div>
                  ))}
                </>
              )}
            </div>
            <div
              className={s.remove}
              onClick={() => {
                if (type === 'upsell') {
                  removeUpsellFromCartAction(cartItem.sku);
                } else if (type === 'main-variants') {
                  removeVariantsMainProductAction(cartItem.id);
                }
              }}
            >
              {type === 'upsell' || (type === 'main-variants' && <RemoveIcon />)}
            </div>
          </div>
          <div className={s.lower}>
            <div className={s.prices}>
              <div className={s.price} style={{ color: Cart.styles.colorPrimary }}>
                {cartItem.price && formatPrice(Number(cartItem.price) + bundleItemsPrice)}
              </div>
              <div className={s.oldPrice} style={{ color: Cart.styles.colorSecondary }}>
                {cartItem.old_price && formatPrice(Number(cartItem.old_price) + bundleItemsOldPrice)}
              </div>
            </div>

            <div className={s.quantity}>
              <button className={s.decrease} onClick={decreaseQuantity}>
                <span style={{ backgroundColor: cartItem.amount > 1 ? '#404040' : '#E5E5E5' }}></span>
              </button>
              <div className={s.number}>{cartItem.amount}</div>
              <button className={s.increase} onClick={increaseQuantity}>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
