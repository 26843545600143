import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './Socials.module.scss';

export const Socials = () => {
  const { footer, Settings } = usewpdataSelectors();
  return (
    <div className={s.socials}>
      <img src={Settings.logoWhite} alt={Settings.brandName} />
      <p className={s.socials__text} dangerouslySetInnerHTML={{ __html: footer.text }}></p>
    </div>
  );
};
