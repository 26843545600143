import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './Benefits.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import LazyLoad from 'react-lazy-load';
const Benefits = () => {
  const { footer } = usewpdataSelectors();
  const isDesktopView = useMediaQuery('(min-width: 47.9375em)');
  return (
    <div className={s.benefits}>
      {footer?.benefits &&
        footer.benefits.map((item, index) => (
          <>
            <div className={s.item} key={index}>
              <LazyLoad>
                <img src={item.icon} alt={item.icon} />
              </LazyLoad>
              <div className={s.textWrapper}>
                <div className={s.title}>{item.tr_title}</div>
                <div className={s.subtitle}>{item.tr_subtitle}</div>
              </div>
            </div>
            {isDesktopView && index !== 3 && <div className={s.desktopSeparator} />}
            {index === 1 && !isDesktopView && <div className={s.seperator} />}
          </>
        ))}
    </div>
  );
};

export default Benefits;
