import React from 'react';
import s from './CompetitorsComparison.module.scss';
import { CheckIcon } from './icons/CheckIcon';
import { XIcon } from './icons/XIcon';
import Button from 'components/Button/CTA/Button';
import { ShieldIcon } from './icons/ShieldIcon';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import LazyLoad from 'react-lazy-load';

const CompetitorsComparison = () => {
  const { CompetitorsComparison, Settings, Theme } = usewpdataSelectors();

  return (
    <div
      className={s.competitorsComparison}
      style={{
        background: CompetitorsComparison.styles.background,
        '--background-odd': CompetitorsComparison.styles.oddTable,
        '--background-even': CompetitorsComparison.styles.evenTable,
        '--background-evenOur': CompetitorsComparison.styles.evenTableOur,
        '--background-oddOur': CompetitorsComparison.styles.oddTableOur,
      }}
    >
      <div className={s.title} data-theme={Theme.heading}>
        {CompetitorsComparison.tableHeading}
      </div>
      <div className={s.tableHeader}>
        <div className={s.firstColumn}>
          <div className={s.title__desktop} data-theme={Theme.heading} style={{ color: Theme.headingColor }}>
            {CompetitorsComparison.tableHeading}
          </div>
        </div>
        <div className={s.secondColumn}>
          <div className={s.productContainer}>
            {CompetitorsComparison?.productImage && (
              <LazyLoad className={s.productImg}>
                <img height="100%" src={CompetitorsComparison?.productImage} alt={Settings.brandName} />
              </LazyLoad>
            )}
            <LazyLoad>
              <img src={Settings.logo} alt={Settings.brandName} />
            </LazyLoad>
          </div>
        </div>
        <div className={s.thirdColumn}>
          <div className={s.productContainer}>
            {CompetitorsComparison?.competitorsImage && (
              <LazyLoad className={s.productImg}>
                <img height="100%" src={CompetitorsComparison?.competitorsImage} alt={Settings.brandName} />
              </LazyLoad>
            )}
            <p>{CompetitorsComparison.otherProduct}</p>
          </div>
        </div>
      </div>
      <div className={s.table}>
        <div className={s.firstColumn}>
          {CompetitorsComparison.repeater &&
            CompetitorsComparison.repeater.map((row, index) => (
              <div key={index} className={s.labelRow}>
                {row.tr_comparison_criteria}
              </div>
            ))}
        </div>
        <div className={s.secondColumn}>
          {CompetitorsComparison.repeater &&
            CompetitorsComparison.repeater.map((row, index) => (
              <div className={s.ourProductRow} key={index}>
                <div className={s.icon}>
                  <CheckIcon fillColor="white" />
                </div>
              </div>
            ))}
          <div className={s.columnFooter}>
            <Button className={s.cta_Button} text={CompetitorsComparison.ctaLabel} />
            <p className={s.columnFooter__text}>
              <ShieldIcon />
              {CompetitorsComparison.moneyBackLabel}
            </p>
          </div>
        </div>
        <div className={s.thirdColumn}>
          {CompetitorsComparison.repeater &&
            CompetitorsComparison.repeater.map((row, index) => (
              <div key={index} className={s.competitorsRow}>
                <div className={s.icon}>{row.comparison_type ? <CheckIcon fillColor="black" /> : <XIcon />}</div>
              </div>
            ))}
        </div>
      </div>
      <div className={s.mobileFooter}>
        <Button text={CompetitorsComparison.ctaLabel} className={`${s.ctaButton__mobile} ${s.cta_Button}`} />
        <p className={s.mobileFooter__text}>
          <ShieldIcon />
          {CompetitorsComparison.moneyBackLabel}
        </p>
      </div>
    </div>
  );
};

export default CompetitorsComparison;
