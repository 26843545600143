import React from 'react';
import { PageRouteSection } from './PageRoutesMobile/PageRouteSection';
import { SubBox } from './SubBox/SubBox';
import { Socials } from './Socials/Socials';
import { PageRoutes } from './PageRoutes/PageRoutes';
import { RightsBox } from './RightsBox/RightsBox';
import s from './Footer.module.scss';
import Benefits from './Benefits/Benefits';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

const Footer = () => {
  const { footer, Layout } = usewpdataSelectors();

  return (
    <footer
      className={s.footer}
      style={{
        background: footer.styles.background,
        color: 'red',
        '--link-hover': footer.styles.linkHoverColor,
        '--footer-primary': footer.styles.primaryColor,
        paddingBottom: Layout.floater.display ? '100px' : '24px',
      }}
    >
      <div className={s.footer__outsideWrapper} style={{ maxWidth: '1168px', padding: '0 16px' }}>
        <Benefits />
        <section className={s.footer__innerWrapper}>
          <SubBox />
          <PageRouteSection />
          <div className={s.footer__socialsRoutesWrapper}>
            <Socials />
            <PageRoutes />
          </div>
          <RightsBox />
        </section>
      </div>
    </footer>
  );
};

export default Footer;
