import React from 'react';
import { useCartSelectors } from 'lib/cartState/selectors';
import CartUpsellCard from '../CartUpsellCard/CartUpsellCard';

const BundleUpsells = () => {
  const { cartProducts } = useCartSelectors();

  if (!cartProducts.upsells.length) {
    return null;
  }
  const bundleUpsells = cartProducts.upsells.filter((item) => {
    return (
      item.product?.enable_color_variants === false &&
      item.product?.enable_size_variants === false &&
      item.bundle?.length > 0 &&
      Array.isArray(item.product.upsell_type) &&
      item.product.upsell_type.some((types) => types === 'cart-picker')
    );
  });

  return (
    <>
      {bundleUpsells &&
        bundleUpsells.map((upsellProduct, index) => {
          const totalBundlePrice = upsellProduct.bundle.reduce((acc, curr) => {
            return Number(acc) + (Number(curr.price) || 0);
          }, 0);
          const totalOldPrice = upsellProduct.bundle.reduce((acc, curr) => {
            return Number(acc) + (Number(curr.old_price) || 0);
          }, 0);

          const bundleDescriptions = Array.isArray(upsellProduct.bundle)
            ? upsellProduct.bundle
                .map((item) => {
                  return item.tr_title;
                })
                .filter(Boolean)
                .join('</br>')
            : '';

          const properties = {
            parentId: upsellProduct.product.id,
            image: upsellProduct.product.image,
            amount: upsellProduct.product.amount,
            display_name: upsellProduct.product.tr_title,
            description: `${upsellProduct.product.tr_description} </br> ${bundleDescriptions}`,
            price: totalBundlePrice + Number(upsellProduct.product.price),
            old_price: Number(totalOldPrice) + Number(upsellProduct.product.old_price),
            bundleDescriptions,
          };

          return (
            <CartUpsellCard
              key={index}
              variant={[]}
              type="bundle"
              cartItem={properties}
              totalQty={upsellProduct.product.amount}
            />
          );
        })}
    </>
  );
};

export default BundleUpsells;
