import React from 'react';
import s from './ContentBlock.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

const SizeChart = () => {
  const { Settings, hero } = usewpdataSelectors();

  return (
    <a className={s.sizeChart} href={hero.chart_link}>
      <div className={s.sizeChart__content}>
        <img src={`${Settings.root}/temp_images/sizeChart.svg`} alt={Settings.brandName} />
        <span> {hero.chart_label} </span>
      </div>
      <img src={`${Settings.root}/temp_images/questionMark.svg`} alt={Settings.brandName} />
    </a>
  );
};

export default SizeChart;
