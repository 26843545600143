import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import React, { useEffect, useState } from 'react';
import s from './Summary.module.scss';
import ShippingIcon from 'components/ProductHero/Icons/ShippingIcon';

const Timer = () => {
  const { hero, Cart } = usewpdataSelectors();

  const initialTime = 30 * 60;
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const minutes = Math.floor((timeLeft % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeLeft % 60).toString().padStart(2, '0');

  return (
    <div className={s.timer}>
      <ShippingIcon fill={Cart.styles.colorPrimary} />
      <p style={{ color: Cart.styles.colorSecondary }}>
        {hero?.shopWithin}{' '}
        <strong style={{ color: Cart.styles.colorPrimary }}>
          {minutes}:{seconds}
        </strong>{' '}
        {hero?.shopWihinTwo}
      </p>
    </div>
  );
};

export default Timer;
