import { useEffect } from 'react';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

export const openZendeskChat = () => {
  let retries = 0;
  const tryOpenChat = () => {
    if (window.zE && typeof window.zE.show === 'function') {
      window.zE.show();
      window.zE.activate();
    } else if (retries < 10) {
      retries++;
      setTimeout(tryOpenChat, 500);
    } else {
      console.warn('Zendesk failed to initialize after multiple attempts.');
    }
  };

  tryOpenChat();
};

const Support = ({ isHeroVisible, floaterVisible }: { isHeroVisible: boolean; floaterVisible: boolean }) => {
  const { hero } = usewpdataSelectors();

  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        offset: {
          vertical: !isHeroVisible && floaterVisible ? '90px' : '0px',
        },
      });
    }
  }, [isHeroVisible]);

  const setting = {
    color: {
      launcher: hero.styles.textColorPrimary || '#fff',
      launcherText: '#fff',
      button: hero.styles.textColorSecondary,
      resultLists: hero.styles.textColorSecondary,
      header: hero.styles.background,
    },
    offset: {
      vertical: '0px',
    },
    launcher: {
      chatLabel: {
        'en-US': 'Need Help',
      },
    },

    contactForm: {
      fields: [
        {
          id: 'description',
          prefill: { '*': 'Question' },
        },
      ],
    },
  };

  const zendeskKey = '7b1143d5-ee03-4e62-a1c6-b6204cd435ce';
  useEffect(() => {
    if (!document.querySelector('script[src*="zendesk.com"]')) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
      document.body.appendChild(script);
    }

    window.zESettings = {
      webWidget: {
        zIndex: 19,
        locale: window.locale,
        color: setting.color,
        launcher: setting.launcher,
        contactForm: setting.contactForm,
        chat: {
          autoOpen: false,
        },
        offset: {
          vertical: '0px',
          horizontal: '0px',
        },

        onReady: function () {
          if (window.zE && typeof window.zE.hide === 'function') {
            window.zE.hide();
          }
        },
      },
    };

    return () => {
      const existingScript = document.querySelector('script[src*="zendesk.com"]');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);

  return null;
};

export default Support;
