'use client';
import { useEffect } from 'react';
import s from './Toggle.module.scss';
import { useCartActions } from '../../../lib/cartState/actions';
import { useCartSelectors } from 'lib/cartState/selectors';

const Toggle = ({ product }) => {
  const { removeUpsellFromCartAction, addUpsellToCartAction } = useCartActions();
  const { cartProducts } = useCartSelectors();
  useEffect(() => {
    if (product.preselect_upsell) {
      addUpsellToCartAction({
        product: product,
      });
    }
  }, []);

  const checked = cartProducts.upsells.some((item) => item.product.sku === product.sku);

  const handleChange = () => {
    if (!checked) {
      addUpsellToCartAction({
        product: product,
      });
    } else {
      removeUpsellFromCartAction(product.id);
    }
  };

  return (
    <label className={s.toggle}>
      <input type="checkbox" checked={checked} className={s.toggle__input} onChange={handleChange} />
      <span className={s.toggle__slider}></span>
    </label>
  );
};

export default Toggle;
