import s from './CartUpsellCard.module.scss';
import RemoveIcon from '../Icons/RemoveIcon';
import { formatPrice } from 'utils/helpers';
import { cartProductItem } from 'lib/cartState/types';
import { useCartActions } from 'lib/cartState/actions';
import { upsellVariantItem } from 'lib/productsState/productsTypes';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

type props = {
  cartItem: cartProductItem['product'];
  variant: upsellVariantItem[];
  type?: 'regular' | 'bundle' | 'variant';
  totalQty: number;
};

const CartUpsellCard = ({ cartItem, totalQty, type = 'regular' }: props) => {
  const { Cart, Theme } = usewpdataSelectors();

  const { removeUpsellFromCartAction, adjustUpsellQuantityAction, removeVariantsUpsellAction } = useCartActions();

  const increaseQuantity = () => {
    adjustUpsellQuantityAction(cartItem.parentId, Number(totalQty) + 1, type === 'variant' ? cartItem.id : null);
  };
  const decreaseQuantity = () => {
    if (cartItem.amount > 1) {
      adjustUpsellQuantityAction(cartItem.parentId, Number(totalQty) - 1, type === 'variant' ? cartItem.id : null);
    }
  };

  return (
    <>
      <div className={s.cartUpsellCard}>
        <div className={s.quantityWrapper}>
          <div className={s.imageWrapper}>
            <div className={s.sumOfItem} style={{ background: Cart.styles.colorPrimary }}>
              {cartItem.amount}x
            </div>
            <div className={s.image}>
              <img
                className={s.productImage}
                src={cartItem.image ?? cartItem.product_image}
                alt={cartItem.tr_display_name ?? cartItem.display_name}
              />
            </div>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.upper}>
            <div className={s.titles}>
              <div className={s.title} data-theme={Theme.heading} style={{ color: Cart.styles.colorPrimary }}>
                {cartItem.tr_display_name ?? cartItem.display_name}
              </div>
              <div
                className={s.description}
                dangerouslySetInnerHTML={{ __html: cartItem.description }}
                style={{ color: Cart.styles.colorSecondary }}
              ></div>
            </div>
            <div
              className={s.remove}
              onClick={() => {
                if (type === 'variant') {
                  removeVariantsUpsellAction(cartItem.parentId, cartItem.id);
                } else {
                  removeUpsellFromCartAction(cartItem.parentId);
                }
              }}
            >
              <RemoveIcon />
            </div>
          </div>
          <div className={s.lower}>
            <div className={s.prices}>
              <div className={s.price} style={{ color: Cart.styles.colorPrimary }}>
                {cartItem.price && formatPrice(cartItem.price)}
              </div>
              <div className={s.oldPrice} style={{ color: Cart.styles.colorSecondary }}>
                {cartItem.old_price && formatPrice(cartItem.old_price)}
              </div>
            </div>

            <div className={s.quantity}>
              <button className={s.decrease} onClick={decreaseQuantity}>
                <span style={{ backgroundColor: cartItem.amount > 1 ? '#404040' : '#E5E5E5' }}></span>
              </button>
              <div className={s.number}>{cartItem.amount}</div>
              <button className={s.increase} onClick={increaseQuantity}>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartUpsellCard;
