import s from './Summary.module.scss';
import { useState, useEffect } from 'react';
import Shield from './Icons/Shield';
import Toggle from '../Toggle/Toggle';
import { useCartSelectors } from '../../../lib/cartState/selectors';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import Timer from './Timer';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { formatPrice } from 'utils/helpers';
import { useproductsdataSelectors } from 'lib/productsState/selectors';

const Summary = () => {
  const { totalSavings, subTotal, CartData } = useCartSelectors();
  const { Cart, Theme, Settings } = usewpdataSelectors();
  const [digitalUpsells, setDigitalUpsells] = useState();
  const { upsells } = useproductsdataSelectors();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const upsellsDigit = upsells.find((item) => {
      return item.upsell_type.some((types) => types === 'digital');
    });

    setDigitalUpsells({ ...upsellsDigit, id: uuidv4() });
  }, []);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const url = '/wp-json/brands/cart/v1/session';
      const response = await axios.post(
        url,
        { payload: CartData },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        let urlSymbol = Settings.link.includes('?') ? '&' : '?';
        window.location.href = `${Settings.link}/${urlSymbol}cart=${response.data.session_hash}&aff=${window.btoa(
          window.affiliate
        )}&entryUrl=${window.btoa(window.entry_url)}&postId=remote`;
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      // isideti sentry exceptiona
      setIsLoading(false);
    }
  };

  return (
    <section className={s.summary}>
      {digitalUpsells &&
        [digitalUpsells].map((item) => {
          const price = Settings.formattedString.replace(/(0\.00|0,00|0)/, item?.price?.toString());
          return (
            <>
              <div className={s.heading}>
                <div>
                  <Shield className={s.shield} />
                  <p className={s.mainHeading} style={{ color: Cart.styles.colorPrimary }}>
                    {item.tr_display_name}
                  </p>
                </div>
                <div>
                  <p className={s.price}>{price}</p>
                  <Toggle product={{ ...item, amount: 1 }} />
                </div>
              </div>
            </>
          );
        })}

      <div className={s.totalSavings}>
        <span>{Cart.savingsLabel}</span>
        <span>-{formatPrice(totalSavings)}</span>
      </div>
      <div className={s.subTotal}>
        <span style={{ color: Cart.styles.colorPrimary }}>{Cart.subtotalLabel}</span>
        <span>{formatPrice(subTotal)}</span>
      </div>
      <button className={`${s.submit}`} data-loading={isLoading} data-theme={Theme.button} onClick={handleSubmit}>
        <span>{Cart.ctaLabel}</span>
        {!isLoading && (
          <>
            <span></span>
            <span>{formatPrice(subTotal)}</span>
          </>
        )}
      </button>
      <Timer />
    </section>
  );
};

export default Summary;
