import React from 'react';
import { useCartSelectors } from 'lib/cartState/selectors';
import CartUpsellCard from '../CartUpsellCard/CartUpsellCard';

const BasicUpsells = () => {
  const { cartProducts } = useCartSelectors();

  if (!cartProducts.upsells.length) {
    return null;
  }

  const basicUpsells = cartProducts.upsells.filter((item) => {
    return (
      item.product?.enable_color_variants === false &&
      item.product?.enable_size_variants === false &&
      item.bundle?.length === 0 &&
      Array.isArray(item.product.upsell_type) &&
      item.product.upsell_type.some((types) => types === 'upsell')
    );
  });

  return (
    <>
      {basicUpsells &&
        basicUpsells.map((upsellProduct, index) => {
          return (
            <CartUpsellCard
              key={index}
              variant={[]}
              cartItem={{ ...upsellProduct.product, parentId: upsellProduct.product.id }}
              totalQty={upsellProduct.product.amount}
            />
          );
        })}
    </>
  );
};

export default BasicUpsells;
