import React from 'react';

const CheckWhite = ({ fill }: { fill: string }) => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2242_46089)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.00293 18C9.0535 18 10.0938 17.7931 11.0644 17.391C12.035 16.989 12.9169 16.3997 13.6598 15.6569C14.4027 14.914 14.9919 14.0321 15.394 13.0615C15.796 12.0909 16.0029 11.0506 16.0029 10C16.0029 8.94943 15.796 7.90914 15.394 6.93853C14.9919 5.96793 14.4027 5.08601 13.6598 4.34315C12.9169 3.60028 12.035 3.011 11.0644 2.60896C10.0938 2.20693 9.0535 2 8.00293 2C5.8812 2 3.84637 2.84285 2.34608 4.34315C0.845784 5.84344 0.00292969 7.87827 0.00292969 10C0.00292969 12.1217 0.845784 14.1566 2.34608 15.6569C3.84637 17.1571 5.8812 18 8.00293 18ZM7.79671 13.2356L12.2412 7.90222L10.8758 6.76444L7.0536 11.3502L5.07582 9.37156L3.81893 10.6284L6.4856 13.2951L7.1736 13.9831L7.79671 13.2356Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2242_46089">
          <rect width="16" height="18" fill={fill ? fill : '#fff'} transform="translate(0.00292969)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckWhite;
