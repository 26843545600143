import React from 'react';
import Button from '../Button/CTA/Button';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import LazyLoad from 'react-lazy-load';

type FeaturesCtaProps = {
  label: string;
  stars: number;
  score: number;
};

const FeaturesCta: React.FC<FeaturesCtaProps> = ({ label, stars, score }) => {
  const { FeaturesList, Settings, CleanSettings } = usewpdataSelectors();

  return (
    <div className="featuresList-ctaBlock">
      <Button text={label} link="blank" attribute="cta-features" className="featuresList-ctaBlock__cta" />
      {!CleanSettings.cleanMode && (
        <>
          <div className="featuresList-ctaBlock__score">
            <LazyLoad height="20px">
              <img
                className="featuresList-ctaBlock__image"
                src={`${window.template_root}/stars/${stars}.svg`}
                alt={Settings.brandName}
              />
            </LazyLoad>
            <span className="featuresList-ctaBlock__reviews" style={{ color: FeaturesList.styles.textColorSecondary }}>
              <strong>{score}</strong>
              <div className="featuresList-ctaBlock__divider"></div>
              <span>Verified Reviews</span>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default FeaturesCta;
