import { useState } from 'react';
import { customerReviewItem } from '../../../lib/wpdataState/wpDataType';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import VerifiedCheckInCircle from './Icons/VerifiedCheckInCircle';
import s from './Masonry.module.scss';
import { decode } from 'html-entities';
import Like from './Icons/Like';

const MasonryItem = ({
  review,
  likeColor = '51D0A1',
  dislikeColor = 'BD1139',
}: {
  review: customerReviewItem;
  likeColor?: string;
  dislikeColor?: string;
}) => {
  const [initialLikes, setInitialLikes] = useState<number>(Number(review.initial_likes));
  const [initialDislikes, setInitialDislikes] = useState<number>(Number(review.initial_dislikes));
  const { customerReviewsBlock, Settings } = usewpdataSelectors();
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const toggleLike = () => {
    if (disliked && !liked) {
      setLiked(true);
      setDisliked(false);
      setInitialDislikes(initialDislikes - 1);
      setInitialLikes(initialLikes + 1);
    } else if (!liked) {
      setLiked(true);
      setInitialLikes(initialLikes + 1);
    } else {
      setLiked(false);
      setInitialLikes(initialLikes - 1);
    }
  };

  const toggleDislike = () => {
    if (liked && !disliked) {
      setInitialLikes(initialLikes - 1);
      setLiked(false);
      setDisliked(true);
      setInitialDislikes(initialDislikes + 1);
    } else if (!disliked) {
      setDisliked(true);
      setInitialDislikes(initialDislikes + 1);
    } else {
      setDisliked(false);
      setInitialDislikes(initialDislikes - 1);
    }
  };

  return (
    <div className={s.item}>
      <div className={s.item_profilepic}>
        {review?.review_images && (
          <img className={s.profilePic} src={review?.review_images[0].image} alt={Settings.brandName} />
        )}
      </div>
      <div className={s.content}>
        <div className={s.nameRow}>
          <p>{review.user_name}</p>
          <span className={s.verified}>
            <VerifiedCheckInCircle />
            <p className={s.fromTablet}>{customerReviewsBlock.verifiedLabel}</p>
            <p className={s.onlyMobile}>{customerReviewsBlock.verifiedLabel.split(' ')[0]}</p>
          </span>
        </div>
        <img
          src={`${window.template_root}/stars/${review.review_score}.svg`}
          style={{ maxHeight: '16px', marginRight: 'auto' }}
          alt={Settings.brandName}
        />
        <p className={s.text}>{decode(review.tr_review_description)}</p>
        <div className={s.seperator} />
        <div className={s.wasHelpful}>
          <span>{customerReviewsBlock.helpfulLabel}</span>
          <div className={s.likeContainer}>
            <div className={s.likeContainer__likeBox} onClick={toggleLike}>
              <Like className={s.likeContainer__icon} color={liked ? likeColor : 'E3E3ED'}></Like>
              <span className={s.likeContainer__number}>{initialLikes}</span>
            </div>
            <div className={s.likeContainer__likeBox} onClick={toggleDislike}>
              <Like
                className={`${s.likeContainer__icon} ${s['likeContainer__icon--dislike']}`}
                color={disliked ? dislikeColor : 'E3E3ED'}
              ></Like>
              <span className={s.likeContainer__number}>{initialDislikes}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasonryItem;
