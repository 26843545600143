import React from 'react';
import s from './imageSlider.module.scss';
import { usewpdataSelectors } from '../../../lib/wpdataState/selectors';
import Marquee from 'react-fast-marquee';

const ImageSlider = () => {
  const { videoTestimonialsImages, Settings } = usewpdataSelectors();

  return (
    <div className={s.imageCarousel}>
      <Marquee>
        {videoTestimonialsImages.repeater &&
          videoTestimonialsImages.repeater.map((item, index: number) => {
            return <img key={index} src={item.user_image} alt={Settings.brandName} className={s.imageCarousel__img} />;
          })}
      </Marquee>
    </div>
  );
};

export default ImageSlider;
